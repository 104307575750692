import React from 'react';
import { FilePath } from '../../Utils/Utils';
const Portfolio = (props) => {
    const { name, image, category } = props.LatestWork

    const backgroundImageStyle = {
        backgroundImage: `url(${FilePath}${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '260px',
        borderRadius: '5px'
    };

    return (
        <div className='portfolio-item-wrap'>
            {/* <img src={`${FilePath}${image}`} alt={name} /> */}

            <div style={backgroundImageStyle}></div>
            <div className="portfolio-item-text">
                <h3 className="title">{name}</h3>
                <p className="cat-text">{category.name}</p>
            </div>
        </div>
    );
};

export default Portfolio;