import React from 'react';
import './Ui.css'
import { Link } from 'react-router-dom';
import { FilePath } from '../../Utils/Utils';
const ServiceCard = (props) => {
    const { slug, sort_description, name, icon } = props.service;

    // For Word Counnt 
    const limitWords = (text, limit) => {
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        }
        return text;
    };

    const limitedName = limitWords(name, 3);
    const limitedDescription = limitWords(sort_description, 10);

    return (
        <div className="ui-card">
            <img src={`${FilePath}${icon}`} alt={name} />
            <h3>{limitedName}</h3>
            <p dangerouslySetInnerHTML={{ __html: limitedDescription }}></p>
            <Link to={`/${slug}`}>more</Link>
        </div>
    );
};

export default ServiceCard;