import React, { useEffect, useState } from 'react';
import './About.css'
import { Col, Container, Row } from 'react-bootstrap';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import SectionTitle from '../Ui/SectionTitle';
import { TeamMember } from '../Ui';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';

const About = () => {

    const [teamData, setTeamData] = useState([])
    const [funFactData, setFunFactData] = useState([])


    useEffect(() => {
        // For Teams Data
        axios.get('/teams')
            .then(res => {
                setTeamData(res.data.teams);
            })
        // For Fun Fact Data
        axios.get('/fun')
            .then(res => {
                setFunFactData(res.data.fun[0]);
            })
    }, [])


    return (
        <div className='about-wrap'>
            <Breadcrumbs
                name='About Us'
            ></Breadcrumbs>
            <section className='about-who-we-are'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12} md={12} className='ms-3'>
                            <h3>About Us</h3>
                            <p>
                                ICT CARE is one of the leading IT farms in Bangladesh.ICT CARE began its journey in 2013, with a view to becoming a proper IT Institute in the country. we started our journey at the first digital city in Bangladesh named Jashore.now we have opend our 2nd official branch at the Capital City of Bangladesh named Dhaka.
                            </p>
                            <p>
                                Basically It's an organisation which is mostly working for entrepreneurs as well as empowering the community for an excellent standard of learning is ensured.
                            </p>

                            <p>
                                Nurturing the young talent by sharing knowledge, providing IT support in their respective demands.
                            </p>

                            <p>
                                Not only we are working to develop ourselves but also we are developing our next generation by providing the IT essential skills,like- Graphics Design, Web Design, Video Editing, Apps Development,  Digital Marketing etc.
                            </p>
                            <p>
                                So getting in touch with an IT Farms like us will be your wise choice to develop yourself.
                            </p>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h3>Our Core </h3>
                                    <p>
                                        Success cannot be evaluated with money.it can be evaluated with service.

                                        We are always committed to maintaining the highest standard.


                                        We will develop your Dream through our support & service.
                                    </p>
                                    <ul>
                                        <li>✔️ Perfect For your business</li>
                                        <li>✔️ Mentorship</li>
                                        <li>✔️ Honesty</li>
                                        <li>✔️ Time Maintain</li>
                                        <li>✔️ Personalized Service</li>
                                        <li>✔️ Custom Work</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h3>Our working Colture</h3>
                                    <p>
                                        We have developed our brilliant service with our brilliant team.we always prioritize to work in a friendly environment where time off and interaction with each other helps us to be together and to learn new things with new & old stuffs.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h3>Our Mission</h3>
                                    <ul>
                                        <li>✅ To be a company that is more connected with their clients.</li>

                                        <li>✅ To be a company that is more reliable then ever in the country & across the globe.</li>

                                        <li>✅  To enable people’s to develop their business & Dreams through us.</li>

                                        <li>  ✅ To Improve Every Life through Innovative Giving in Education, Community and the Environment.</li>

                                        <li>✅ Utilize the rising talents to improve the economic condition of their own as well as Country.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="row  mt-3">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h3>Message From Founder & CEO</h3>
                                    <p>
                                        It’s always been a pleasure to welcome you all to "ICT CARE", nurturing all aged talents with extra care is why they are special to us and why we are special.
                                    </p>

                                    <p>
                                        As one of the leading IT Farms in Bangladesh, we are working with the vission of making the nations assets more competent as well as making the nation proficient in the global IT Village by building a platform that serves business owners as well as the students to become successful in their life.
                                    </p>

                                    <p>
                                        We are striving to develop better, faster & smarter enterprenure with the vission of Digital Bangladesh.
                                    </p>

                                    <p>
                                        The changes have been made every second to deal with technology upgradation
                                    </p>

                                    <p>
                                        Be competent, be skilled, be focused, be aware of your career, be patience & be honest always.
                                    </p>

                                    <p>
                                        It is very much possible that our enterprenure & youth will make a revolutionary change in Bangladesh.
                                    </p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="our-fun-fact">
                <Container>
                    <SectionTitle
                        subtitle=''
                        title='Our Fun Facts'
                        des=''
                    />
                    <Row className='text-center my-3'>
                        <Col lg={4} md={4} sm={12}>
                            <div className="counterup-wrap">
                                <h5 className="counter-count">{funFactData.done_project}</h5>
                                <h3 className='counter-title'>Project Done</h3>
                                <p className="description">The standard chunk of Lorem Ipsum used since the 1500s is
                                    reproduced below for those.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className="counterup-wrap">
                                <h5 className="counter-count">{funFactData.active_customer}</h5>
                                <h3 className='counter-title'>Active Customar</h3>
                                <p className="description">The standard chunk of Lorem Ipsum used since the 1500s is
                                    reproduced below for those.</p>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                            <div className="counterup-wrap">
                                <h5 className="counter-count">{funFactData.ongoing_project}</h5>
                                <h3 className='counter-title'>On going Project</h3>
                                <p className="description">The standard chunk of Lorem Ipsum used since the 1500s is
                                    reproduced below for those.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Skilled Team  */}
            <section className='skilled-team-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Meet Our Skilled Team'
                        title='Experience Team Members'
                    />
                    <Row className='my-5'>
                        {
                            teamData.map(SingleTeam =>
                                <Col xl={4} lg={4} col={12} key={SingleTeam.id}>
                                    <TeamMember
                                        SingleTeam={SingleTeam}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>
            <ScrollToTop />
        </div >
    );
};

export default About;