import axios from "axios";
const baseURL = "https://backend.ictcare.com.bd/api/";
// const baseURL = "http://127.0.0.1:8000/api/";
export default axios.create({
    baseURL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});