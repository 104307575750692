import React from 'react';
import './Error.css'
import errorImage from '../../img/404.svg'
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ScrollToTop from '../ScrollToTop';
const Error = () => {
    return (
        <div className='error-page'>
            <Header></Header>
            <Container>
                <Row>
                    <Col lg={4} className="mx-auto text-center my-5">
                        <div class="inner">
                            <img src={errorImage} alt="" />
                            <div class="error-button mt-3">
                                <a class="btn btn-primary" href="/">Back To Homepage</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer></Footer>
            <ScrollToTop />
        </div>
    );
};

export default Error;