import React from 'react';

const TestimonialCardPage = (props) => {
  const { description, name, company } = props.Testimonial;

  return (
    <div className="Testimonial-Card">
      <i className='ri-double-quotes-l'></i>
      <p>{description}</p>
      <h4>{name}</h4>
      <span>{company}</span>
    </div>
  );
};

export default TestimonialCardPage;
