import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './images/ict-care-logo.png'
import './Header.css'
const Header = () => {
    return (
        <Navbar collapseOnSelect expand="lg" className='header-menu'>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Header logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                    <Nav>
                        <Nav.Link as={Link} to='/'>Home</Nav.Link>
                        <Nav.Link as={Link} to='/about'>About</Nav.Link>
                        <Nav.Link as={Link} to='/services'>Services</Nav.Link>
                        <Nav.Link as={Link} to='/portfolio'>Portfolio</Nav.Link>
                        <Nav.Link as={Link} to='/testimonials'>Testimonials</Nav.Link>
                        <Nav.Link as={Link} to='/whychoseme'>Why Chose us</Nav.Link>
                        <Nav.Link as={Link} to='/contact'>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;