import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Col, Container, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css'
import ScrollToTop from '../ScrollToTop';
const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_qkycf6y', 'template_62j9zcm', form.current, 'iJG2qCVCrtWnztcpC')
            .then((result) => {
                console.log(result.text);
                toast.success('Message sent !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                e.target.reset()
            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <>
            <Breadcrumbs
                title='Contact Us'
                name='Connected With Us'
            ></Breadcrumbs>
            <section className="contactUs-wrap">
                <Container>
                    <Row>
                        <Col md={4} lg={4}>
                            <div className="contact-header-item">
                                <h3 className="contact-header-title">
                                    Contact With Phone Number
                                </h3>
                                <a href="tel:+8801859597273">+88 01859-597273</a>
                            </div>
                        </Col>
                        <Col md={4} lg={4}>
                            <div className="contact-header-item">
                                <h3 className="contact-header-title">
                                    Email Address
                                </h3>
                                <a href="mailto:ictcaremarketing@gmail.com">ictcaremarketing@gmail.com</a>
                            </div>
                        </Col>
                        <Col md={4} lg={4}>
                            <div className="contact-header-item">
                                <h3 className="contact-header-title">
                                    Location
                                </h3>
                                <p>
                                    Mirpur DOHS Road no 9 Avenue 10 House No 1017 Flat 7F.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='contactUs-form' id='contactUs'>
                <Container>
                    <Row>
                        {/* <Col md={6}>
                            <img className='img-fluid' src={mapPleaceholderImg} alt="" />
                            <p className='bg-warning p-3 text-light'>
                                Google map Will Be Pleaced Here !
                            </p>
                        </Col> */}
                        <Col md={6} className='mx-auto'>
                            <form ref={form} onSubmit={sendEmail}>
                                <Row>
                                    <Col>
                                        <input placeholder='Your Name' required className='form-control form-input' type="text" name="user_name" />
                                    </Col>
                                    <Col>
                                        <input placeholder='Your Email' required className='form-control form-input' type="email" name="user_email" />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <textarea required placeholder='Your Message ...' className='form-control form-input mb-0' name="message" cols="30" rows="10" />
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <input className='view-more-btn' type="submit" value="Send" />
                                </div>

                                <ToastContainer />
                            </form>
                        </Col>
                    </Row>
                </Container>
                <ScrollToTop />
            </section >
        </>
    );
};

export default Contact;
