export const FilePath = 'https://backend.ictcare.com.bd/uploads/'
// export const FilePath = 'http://127.0.0.1:8000/uploads/'

export const Services = [{
    id: 1,
    name: 'Web Design & Development',
    href: '/'
},
{
    id: 2,
    name: 'Graphic Desgin',
    href: '/'
},
{
    id: 3,
    name: 'Business Design',
    href: '/'
},
{
    id: 4,
    name: 'App Development',
    href: '/'
},
{
    id: 5,
    name: 'Facebook Markting',
    href: '/'
}
];

export const QuickLink = [{
    id: 1,
    name: 'About',
    href: '/about'
},
{
    id: 2,
    name: 'Testimonials',
    href: '/testimonials'
},
{
    id: 3,
    name: 'Our Blog',
    href: '/'
},
{
    id: 4,
    name: 'FAQs',
    href: '/'
}
];
