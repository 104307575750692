import React from 'react';
import './Ui.css'
import { FilePath } from '../../Utils/Utils';
const Card = (props) => {
    const { name, sort_description, icon } = props.data;
    return (
        <div className="ui-card">
            {/* <i className={icon}></i> */}
            <img src={`${FilePath}${icon}`} alt="{name}" />
            <h3>{name}</h3>
            <p>{sort_description}</p>
        </div>
    );
};

export default Card;