import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css'
import { FilePath } from '../../Utils/Utils'
import { Card, TestimonialCard, Customar, Portfolio, TeamMember } from '../Ui/index'
import './Home.css'
import SectionTitle from '../Ui/SectionTitle';
import ServiceCard from '../Ui/ServiceCard';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';

const Home = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [settingsData, setSettingsData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [homeData, setHomeData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);
    const [teamData, setTeamData] = useState([])
    const [whychooseusData, setWhychooseusData] = useState([]);

    useEffect(() => {
        axios.get('/homepage')
            .then(res => {
                setSettingsData(res.data.settings[0]);
                setHomeData(res.data);
                setServiceData(res.data.services);
                setProjectsData(res.data.projects);
            })
        // For Teams Data
        axios.get('/teams')
            .then(res => {
                setTeamData(res.data.teams);
            })
        // For WhyChooseus Data
        axios.get('/whychooseus')
            .then(res => {
                setWhychooseusData(res.data.choose);
            })
    }, [])

    return (
        <>
            {/* Hero Area */}
            <section className="hero-area-wrap">
                <div className="content text-center">
                    <h1>We focusing on- Creativity, Honesty, Time Sense at
                        <span className="hero-ribbon-text">ICT Care.</span>
                    </h1>
                    <p>
                        We served all kinds of Graphic Design, Website Design, Digital Marketing, software development & Entrepreneur's Workshop services.
                        Let's discuss about the possibilities of you business.
                    </p>
                </div>
                <div className="hero-button text-center">
                    <div className="btn-group">
                        {/* <a href="/" className="btn-1">Learn More</a> */}
                        <Link to='/about' className="ic-primary-button">Learn More</Link>
                        <div className="play-btn" onClick={handleShow}>
                            <i className="ri-play-fill"></i>
                        </div>
                    </div>
                </div>

                <Modal centered show={show} onHide={handleClose}>
                    <iframe width="560" height="315" src={settingsData.youtube_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                    </iframe>
                </Modal>
                {/*<Container>*/}

                {/*<Row className='align-items-center'>*/}
                {/*    <Col md={6}>*/}
                {/*        <div className="hero-area-content">*/}
                {/*            <h4 className='section-sub-title'>IT services Agency</h4>*/}
                {/*            <h1 className='section-main-title'>{settingsData.hero_heading}</h1>*/}

                {/*            <p className="hero-text" dangerouslySetInnerHTML={{ __html: settingsData['hero_description'] }}></p>*/}

                {/*            <div className="btn-group">*/}
                {/*                /!* <a href="/" className="btn-1">Learn More</a> *!/*/}
                {/*                <Link to='/about' className="btn-1">Learn More</Link>*/}
                {/*                <div className="play-btn" onClick={handleShow}>*/}
                {/*                    <i className="ri-play-fill"></i>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <Modal centered show={show} onHide={handleClose}>*/}
                {/*                <iframe width="560" height="315" src={settingsData.youtube_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>*/}
                {/*                </iframe>*/}
                {/*            </Modal>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*    <Col md={6}>*/}
                {/*        <img className='img-fluid' src={`${FilePath}${settingsData.hero_image}`}*/}
                {/*            alt="" />*/}
                {/*    </Col>*/}
                {/*</Row>*/}


                {/*</Container>*/}
                
            </section>

           


            {/* Service  */}
            <section className='what-we-do-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Services'
                        title='How can we help you?'
                        des="We served all kinds of Graphic Design, Website Design, Digital Marketing, software development & Entrepreneur's Workshop services."
                    />
                    <Row className='my-5'>
                        {
                            serviceData && serviceData?.slice(0, 3).map(service =>
                                <Col key={service.id} xl={4} lg={6}>
                                    <ServiceCard
                                        service={service}
                                    ></ServiceCard>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>

            {/* Our Project */}
            <section className="our-project">
            <Container>
                    <SectionTitle
                        subtitle='Latest Work'
                        title='Our Project'
                        des='Elevating Brands with Innovative Design Solutions: Your Professional Brand Partner'
                    />
                    <Row className='my-5'>
                        {
                            projectsData.slice(0, 6).map(LatestWork =>
                                <Col key={LatestWork.id} className="col-lg-4 col-md-4 col-12 padding-13">
                                    <Portfolio
                                        LatestWork={LatestWork}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <Link className='view-more-btn mx-auto' to='/portfolio'>View More Project</Link>
                        </div>
                    </div>
                </Container>
            </section>

            {/* Why Chose Us Area  */}
            <section className='why-chosse-us-wrap'>
                <Container>
                    <SectionTitle
                        title='Why choose us?'
                        des='We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards. '
                    />
                    <Row className='my-5'>
                        {
                            whychooseusData.slice(0, 3).map(WChoseUs =>
                                <Col key={WChoseUs.id} xl={4} lg={6}>
                                    <Card
                                        data={WChoseUs}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>

            {/* Our Customers  */}
            <section className="our-customers-wrap">
                <Container fluid>
                    <SectionTitle
                        subtitle=''
                        title='Our Customers'
                        des="We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. By putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client."
                    />
                    <Row className='my-5'>
                        <Customar></Customar>
                    </Row>
                </Container>
            </section>

            {/* Testimonials  */}
            <section className='testimonials-wrap'>
                <Container fluid>
                    <SectionTitle
                        subtitle='Testimonials'
                        title='Whats Our Clients Say?'
                    />
                    <Row className='my-5'>
                        <TestimonialCard></TestimonialCard>
                    </Row>
                </Container>
            </section>

            {/* Skilled Team  */}
            <section className='skilled-team-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Meet Our Skilled Team'
                        title='Experience Team Members'
                    />
                    <Row className='my-5'>
                        {
                            teamData && teamData?.slice(0, 3).map(SingleTeam =>
                                <Col xl={4} md={4} lg={4} key={SingleTeam.id}>
                                    <TeamMember
                                        SingleTeam={SingleTeam}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>
            <ScrollToTop />
        </>
    );
};

export default Home;