import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Col, Container, Row } from 'react-bootstrap';
import SectionTitle from '../Ui/SectionTitle';
import { Customar, TestimonialCard } from '../Ui';
import ServiceCard from '../Ui/ServiceCard';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';

const Services = () => {

    const [serviceData, setServiceData] = useState([]);

    useEffect(() => {
        axios.get('/homepage')
            .then(res => {
                setServiceData(res.data.services);
            })
    }, [])


    return (
        <div>
            <Breadcrumbs
                name='Our Services'
            ></Breadcrumbs>

            {/* Service  */}
            <section className='what-we-do-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Services'
                        title='How can we help you?'
                        des='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.'
                    />
                    <Row className='my-5'>
                        {
                            serviceData && serviceData?.map(service =>
                                <Col key={service.id} xl={4} lg={6} >
                                    <ServiceCard
                                        service={service}
                                    ></ServiceCard>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>

            {/* Our Customers  */}
            <section className="our-customers-wrap">
                <Container fluid>
                    <SectionTitle
                        subtitle=''
                        title='Our Customers'
                        des="We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. By putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client."
                    />
                    <Row className='my-5'>
                        <Customar></Customar>
                    </Row>
                </Container>
            </section>

            {/* Testimonials  */}
            <section className='testimonials-wrap'>
                <Container fluid>
                    <SectionTitle
                        subtitle='Testimonials'
                        title='Whats Our Clients Say?'
                        des=''
                    />
                    <Row className='my-5'>
                        <TestimonialCard />
                    </Row>
                </Container>
            </section>
            <ScrollToTop />
        </div>
    );
};

export default Services;