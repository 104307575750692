import React from 'react';
import { Col, Row } from 'react-bootstrap';

const SectionTitle = (props) => {
    return (
        <Row>
            <Col className='mx-auto text-center'>
                <div className="section-title-wrap">
                    {/*<h4>{props.subtitle}</h4>*/}
                    <h2>{props.title}</h2>
                    {/*<p>{props.des}</p>*/}
                </div>
            </Col>
        </Row>
    );
};

export default SectionTitle;