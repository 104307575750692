import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import { Col } from 'react-bootstrap';
import axios from '../../Utils/axios';

const TestimonialCard = () => {

    const [testimonialData, setTestimonialData] = useState([]);


    useEffect(() => {
        axios.get('/homepage')
            .then(res => {
                setTestimonialData(res.data.testimonials)
            })
    }, [])


    return (
        <Marquee pauseOnHover={true} speed={20} delay={3}>
            {
                testimonialData.map(Testimonial => (
                    <Col key={Testimonial.id} xl={4} lg={4} md={4}>
                        <div className="Testimonial-Card" style={{ width: '400px', marginRight: '15px' }}>
                            <i className='ri-double-quotes-l'></i>
                            <p>{Testimonial.description}</p>
                            <h4>{Testimonial.name}</h4>
                            <span>{Testimonial.company}</span>
                        </div>
                    </Col>
                ))
            }
        </Marquee>
    );
};

export default TestimonialCard;
