import React, { useEffect, useState } from 'react';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FilePath } from '../../Utils/Utils';
import SectionTitle from './SectionTitle';
import Customar from './Customar';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Card from './Card';
import OrderModal from "./OrderModal";


const ServiceDetails = () => {

    const getCurrentSlug = useParams();
    const [serviceData, setServiceData] = useState([]);
    const [whychooseusData, setWhychooseusData] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [packageInfo, setPackageInfo] = React.useState({});



    useEffect(() => {
        axios.get(`/service/${getCurrentSlug.id}`)
            .then(res => {
                setServiceData(res.data.service);
            })

        axios.get('/whychooseus')
            .then(res => {
                setWhychooseusData(res.data.choose);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ['getCurrentSlug.id'])

    const handlePackageOrderModal = (packageType) => {
        let packageInfo = {};
        if(packageType === 'basic') {
            const data = {
                service_id: serviceData.id,
                name: serviceData.basic_package_name,
                price: serviceData.basic_package_price,
                features: serviceData.basic_package_features,
            }
            packageInfo = data;
        }else if(packageType === 'standard') {
            const data = {
                service_id: serviceData.id,
                name: serviceData.standard_package_name,
                price: serviceData.standard_package_price,
                features: serviceData.standard_package_features,
            }
            packageInfo = data;
        }else {
            const data = {
                service_id: serviceData.id,
                name: serviceData.premium_package_name,
                price: serviceData.premium_package_price,
                features: serviceData.premium_package_features,
            }
            packageInfo = data;
        }
        setPackageInfo(packageInfo);
        setModalShow(true);
    }




    return (
        <>
            <Breadcrumbs
                title='Details About'
                name={serviceData.name}
            />
            <Container className='py-5'>
                <Row>
                    <Col md={8} className='mx-auto'>
                        <div className="services-details">
                            <div className="services-image">
                                <img className='img-fluid rounded-4' src={`${FilePath}${serviceData.service_image}`}
                                     alt={serviceData.name}/>
                            </div>

                            <div className="services-details-content py-3">
                                <h2 className="servies-details-title">{serviceData.name}</h2>
                                <p dangerouslySetInnerHTML={{__html: serviceData.description}}></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>


            {/*service packages*/}
            {
                serviceData.basic_package_name != null &&
                <section id="generic_price_table">
                    <div className="container">
                        <SectionTitle
                            subtitle='Packages'
                            title='Check our packages here'
                            des=''
                        />
                        <div className="row my-5">
                            <div className="col-md-4">
                                <div className="generic_content clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <h2> {serviceData.basic_package_name}</h2>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">৳</span>
                                                <span className="currency">{serviceData.basic_package_price}</span>
                                                {/*<span className="month">/One time</span>*/}
                                            </span>
                                            <div className="description">
                                                <p>
                                                    {serviceData.basic_package_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            {serviceData.basic_package_features.map((bpfeature, index) => (
                                                <li key={index}>{bpfeature}</li>
                                            ))}

                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <button
                                            type="button"
                                            onClick={() => handlePackageOrderModal('basic')}
                                        >Buy now</button>
                                    </div>
                                </div>
                            </div>
                            { (serviceData.standard_package_price > 0 && serviceData.standard_package_description) && (
                                <div className="col-md-4">
                                <div className="generic_content active clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <h2> {serviceData.standard_package_name}</h2>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">৳</span>
                                                <span className="currency">{serviceData.standard_package_price}</span>
                                                {/*<span className="month">/One time</span>*/}
                                            </span>
                                            <div className="description">
                                                <p>
                                                    {serviceData.standard_package_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            {serviceData.standard_package_features.map((stpFeature, index) => (
                                                <li key={index}>{stpFeature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <button
                                            type="button"
                                            onClick={() => handlePackageOrderModal('standard')}
                                        >Buy now</button>
                                    </div>
                                </div>
                            </div>
                            ) }
                            
                            { (serviceData.premium_package_price > 0 && serviceData.premium_package_description) && (
                                <div className="col-md-4">
                                <div className="generic_content clearfix">
                                    <div className="generic_head_price clearfix">
                                        <div className="generic_head_content clearfix">
                                            <div className="head_bg"></div>
                                            <div className="head">
                                                <h2> {serviceData.premium_package_name}</h2>
                                            </div>
                                        </div>
                                        <div className="generic_price_tag clearfix">
                                            <span className="price">
                                                <span className="sign">৳</span>
                                                <span className="currency">{serviceData.premium_package_price}</span>
                                                {/*<span className="month">/One time</span>*/}
                                            </span>
                                            <div className="description">
                                                <p>
                                                    {serviceData.premium_package_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="generic_feature_list">
                                        <ul>
                                            {serviceData.premium_package_features.map((ppFeature, index) => (
                                                <li key={index}>{ppFeature}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="generic_price_btn clearfix">
                                        <button
                                            type="button"
                                            onClick={() => handlePackageOrderModal('premium')}
                                        >Buy now</button>
                                    </div>
                                </div>
                            </div>
                            ) }
                            
                        </div>
                    </div>
                </section>

            }


            {/* Why Chose Us Area  */}
            <section className='why-chosse-us-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Why Choose Us'
                        title='Why choose us?'
                        des='We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards. '
                    />
                    <Row className='my-5'>
                        {
                            whychooseusData.slice(0, 3).map(WChoseUs =>
                                <Col key={WChoseUs.id} xl={4} lg={6}>
                                    <Card
                                        data={WChoseUs}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>


            {/* Our Customers  */}
            <section className="our-customers-wrap bg-white">
                <Container fluid>
                    <SectionTitle
                        subtitle=''
                        title='Our Customers'
                        des="We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. By putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client."
                    />
                    <Row className='my-5'>
                        <Customar></Customar>
                    </Row>
                </Container>
            </section>

            <OrderModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                packageInfo={packageInfo}
            />

            <ScrollToTop/>
        </>
    );
};

export default ServiceDetails;