import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../img/light-logo.png'
import './Footer.css'
import { Services, QuickLink } from '../../Utils/Utils'
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <section className='footer-wrap m-0 p-0'>
            <Container className='pt-5 pb-2'>
                <Row>
                    <Col xl={3} lg={6}>
                        <div className="f-widget widget-1">
                            <div className="f-logo">
                                <img src={logo} alt="ICT Care" />
                            </div>
                            <div className="f-desc">
                                <p>
                                    ICT Care is dedicated to developing the highest quality of IT solutions for small to big business leaders, delivered with ingenuity, creativity, and originality.
                                </p>
                            </div>
                            <div className="f-social-icons">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/ictcare.com.bd">
                                            <i className='ri-facebook-fill'></i>
                                        </a>
                                        <a href="/">
                                            <i className="ri-twitter-fill"></i>
                                        </a>
                                        <a href="https://www.linkedin.com/company/ict-care/about/">
                                            <i className="ri-linkedin-fill"></i>
                                        </a>
                                        <a href="https://www.youtube.com/ictcare">
                                            <i className="ri-youtube-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xl={3} lg={6}>
                        <div className="f-widget widget-2">
                            <h3 className="f-title">Quick Links</h3>
                            <ul className="f-menu">
                                {
                                    QuickLink.map(QuickLink => <li key={QuickLink.id}>
                                        <Link to={QuickLink.href}>{QuickLink.name}</Link>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col xl={3} lg={6}>
                        <div className="f-widget widget-3">
                            <h3 className="f-title">Our Services</h3>
                            <ul className="f-menu">
                                {
                                    Services.map(Service => <li key={Service.id}><a href={Service.href}>{Service.name}</a></li>)
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col xl={3} lg={6}>
                        <div className="f-widget widget-4">
                            <h3 className="f-title">Contact Us</h3>
                            <ul className="f-menu">
                                <li><a href="/"> <i className='ri-phone-fill me-1'></i> +880 1859 59 72 73 </a></li>
                                <li><a href="/"> <i className='ri-mail-fill me-1'></i> ictcaremarketing@gmail.com</a></li>
                                <li><a href="/"> <i className="ri-map-pin-fill me-1"></i> Mirpur DOHS Road no 9 Avenue 10 House No 1017 Flat 7F.</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-cr">
                <div className="container">
                    <div className="footer-cr-container">
                        <div className="p-left">
                            <p>2015 - 2023 © All rights reserved by ICT Care</p>
                        </div>
                        <div className="p-right">
                            <ul className="cr-menu">
                                <li><a href="/">Privacy Policy</a></li>
                                <li><a href="/">Terms &amp; Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;