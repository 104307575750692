import React from 'react';
import { Main, Home, About, Contact, Portfolio, Services, Testimonials, WhyChoseMe, Error } from './Components';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ServiceDetails } from './Components/Ui';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main></Main>,
      children: [
        {
          path: '/',
          element: <Home></Home>
        },
        {
          path: '/about',
          element: <About></About>
        },
        {
          path: '/contact',
          element: <Contact></Contact>
        },
        {
          path: '/portfolio',
          element: <Portfolio></Portfolio>
        },
        {
          path: '/portfolio/:slug',
          element: <Portfolio></Portfolio>
        },
        {
          path: '/services',
          element: <Services></Services>
        },
        {
          path: '/:id',
          element: <ServiceDetails></ServiceDetails>
        },
        {
          path: '/testimonials',
          element: <Testimonials></Testimonials>
        },
        {
          path: '/whychoseme',
          element: <WhyChoseMe></WhyChoseMe>
        }
      ]
    },
    {
      path: '*',
      element: <Error></Error>
    }
  ])
  return (
    <div>
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
};

export default App;
