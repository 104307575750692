import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Col, Form, Row} from "react-bootstrap";
import axios from "../../Utils/axios";

const OrderModal = ({packageInfo, ...props}) => {

    const [state, setState] = useState({
        fullname: '',
        phone: '',
        email: '',
        bussinessname: '',
        address: '',
        note: '',
        price: '',
        payment_status: false,
        method: '',
        payment_number: '',
        transection_id: '',
        bank_name: '',
        docs: [],
        errors: [],
    });

    const [isOrderPlaced, setIsOrderPlaced] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [payMethods, setPayMethods] = useState([]);

    useEffect(() => {
        // For Teams Data
        axios.get('/mfs')
            .then(res => {
                setPayMethods(res.data.data);
            })
    }, [])

    console.log("setPayMethods", payMethods)

   const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

       // console.log("value", value);
        const name = e.target.name;
        const errors = state.errors;
        errors[name] = '';

       setState({
           ...state,
           [name]: value,
           errors
       })
    }
    const selectDocument = (input) => {
        if (input.target.files) {
            const reader = new FileReader();
            setState({
                ...state,
                docs: input.target.files
            });
        }
    }
    const validateEmail = (mail) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
    }
    const handleValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        if (!fields.fullname) {
            formIsValid = false;
            errors.fullname = "Full name field is required.";
        }

        if (!fields.phone) {
            formIsValid = false;
            errors.phone = "Phone number field is required.";
        }

        if (!fields.bussinessname) {
            formIsValid = false;
            errors.bussinessname = "Business name field is required.";
        }

        if (fields.email && !validateEmail(fields.email)) {
            formIsValid = false;
            errors.email = "Please enter a valid email address or leave it blank";
        }

        if (!fields.address) {
            formIsValid = false;
            errors.address = "Address field is required.";
        }

        if (fields.payment_status === true && fields.method !== 'bank' && !fields.payment_number) {
            formIsValid = false;
            errors.payment_number = "Payment number field is is required.";
        }

        if (fields.payment_status === true && !fields.method) {
            formIsValid = false;
            errors.method = "Payment method field is is required.";
        }

        if (fields.payment_status === true && fields.method !== 'bank' && !fields.transection_id) {
            formIsValid = false;
            errors.transection_id = "Transaction id field is is required.";
        }
        if (fields.payment_status === true && fields.method === 'bank' && !fields.bank_name) {
            formIsValid = false;
            errors.bank_name = "Bank name field is is required.";
        }



        setState({
            ...state,
            errors: errors
        });

        return formIsValid;
    }

   const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();

        formData.append('service_id', packageInfo.service_id);
        formData.append('price', packageInfo.price);
        formData.append('packageName', packageInfo.name);

        packageInfo.features?.forEach(feature => {
            formData.append('features[]', feature);
        })

        formData.append('fullname', state.fullname);
        formData.append('phone', state.phone);
        formData.append('email', state.email);
        formData.append('bussinessname', state.bussinessname);
        formData.append('address', state.address);
        formData.append('note', state.note);
        formData.append('paid_by_customer', state.payment_status);

        if(state.payment_status && state.method === 'bank') {
            formData.append('payment_type', 'bank');
        }
        if(state.payment_status && state.method !== 'bank') {
            formData.append('payment_type', 'mfs');
        }

        formData.append('method', state.method);
        formData.append('payment_number', state.payment_number);
        formData.append('transection_id', state.payment_number);
        formData.append('bank_name', state.bank_name);
        formData.append('docs', state.docs);

        if (handleValidation()) {
            setIsSubmitted(true);
            axios
                .post('/order', formData)
                .then((res) => {
                    if(res.status === 200) {
                        setIsOrderPlaced(true);
                    }else {
                        setIsSubmitted(false);
                    }
                })
                .catch(err => {
                    setIsSubmitted(false);
                    setIsOrderPlaced(false);
                });
        }else {
            console.log("erros", state.errors);
        }
    }

    const { errors } = state;

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {!isOrderPlaced && (
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Place order for {packageInfo.name} package
                    </Modal.Title>
                </Modal.Header>
            ) }

            {isOrderPlaced ?
                (
                    <div>
                        <Modal.Body>
                            <div className="order-success-page">
                                <div className="card-success">
                                    <div className="success-checkmark">
                                        <i className="checkmark">✓</i>
                                    </div>
                                    <h2 className="success-h2">Success</h2>
                                    <p>We received your order;<br/> we'll contact you as soon as possible! </p>
                                    <div className="order-details">
                                        <div className="order-image">
                                            <img/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="rounded-0"
                                onClick={() => window.location.reload() }
                            >Ok, Close</Button>
                        </Modal.Footer>
                    </div>
                ) :
                (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Full Name *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="fullname"
                                            onChange={handleChange}
                                            value={state.fullname}
                                            placeholder="Enter your full name"
                                        />
                                        {state.errors.fullname &&
                                            <div className="error-message">{state.errors.fullname}</div>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Phone Number *</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="phone"
                                            onChange={handleChange}
                                            value={state.phone}
                                            placeholder="Enter your phone no"
                                        />
                                        {state.errors.phone &&
                                            <div className="error-message">{state.errors.phone}</div>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            value={state.email}
                                            placeholder="Enter your email"
                                        />
                                        {state.errors.email &&
                                            <div className="error-message">{state.errors.email}</div>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Business Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="bussinessname"
                                            onChange={handleChange}
                                            value={state.bussinessname}
                                            placeholder="Enter your business name"
                                        />
                                        {state.errors.bussinessname &&
                                            <div className="error-message">{state.errors.bussinessname}</div>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Your Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="address"
                                            onChange={handleChange}
                                            value={state.address}
                                            placeholder="Write your address"
                                        />
                                        {state.errors.address &&
                                            <div className="error-message">{state.errors.address}</div>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Write Your Note</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="note"
                                            onChange={handleChange}
                                            value={state.note}
                                            placeholder="Write note if needed"
                                        />
                                        {state.errors.note && <div className="error-message">{state.errors.note}</div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-1 mb-3">
                                <Col>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="I want to pay now"
                                        name="payment_status"
                                        onChange={handleChange}
                                        checked={state.payment_status === true}
                                    />
                                    {state.errors.payment_status &&
                                        <div className="error-message">{state.errors.payment_status}</div>}
                                </Col>
                            </Row>
                            {state.payment_status === true && (
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Payment By *</Form.Label>
                                            <Form.Select aria-label="Default select example" name="method"
                                                         onChange={handleChange}>
                                                <option disabled selected>Open this select menu</option>
                                                {payMethods?.map(payMethod => (
                                                    <option value={payMethod.name}>{payMethod.name}</option>
                                                ))}

                                                <option value="bank">Bank account</option>
                                            </Form.Select>
                                            {state.errors.method &&
                                                <div className="error-message">{state.errors.method}</div>}
                                        </Form.Group>
                                    </Col>
                                    {state.method === 'bank' &&
                                        (
                                            <Col xs={12} md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Bank name <small>(The bank you send the payment)</small> *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="bank_name"
                                                    value={state.bank_name}
                                                    onChange={handleChange}
                                                />
                                                {state.errors.bank_name &&
                                                    <div className="error-message">{state.errors.bank_name}</div>}
                                            </Form.Group>
                                        </Col>
                                        )}
                                    {state.method !== 'bank' &&
                                        (
                                            <Col xs={12} md={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Payment Number *</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="payment_number"
                                                    value={state.payment_number}
                                                    onChange={handleChange}
                                                />
                                                {state.errors.payment_number &&
                                                    <div className="error-message">{state.errors.payment_number}</div>}
                                            </Form.Group>
                                        </Col>
                                         )
                                    }
                                    {state.method !== 'bank' &&
                                        (
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Transaction id *</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="transection_id"
                                                        onChange={handleChange}
                                                        value={state.transection_id}
                                                    />
                                                    {state.errors.transection_id &&
                                                        <div className="error-message">{state.errors.transection_id}</div>}
                                                </Form.Group>
                                            </Col>
                                         )
                                    }

                                </Row>
                            )}

                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        <Form.Group controlId="formFileMultiple" className="mb-3">*/}
                            {/*            <Form.Label>Select documents</Form.Label>*/}
                            {/*            <Form.Control type="file" name="docs" onChange={selectDocument} multiple/>*/}
                            {/*        </Form.Group>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="danger"
                                onClick={props.onHide}
                                className="rounded-0"
                                disabled={isSubmitted}
                            >Close</Button>
                            <Button type="submit" className="rounded-0 d-flex align-items-center" disabled={isSubmitted}>
                                {isSubmitted && (
                                    <span className="spinner-border me-1" role="status">
                                        <span className="sr-only"></span>
                                    </span>
                                )}
                                Place Order
                            </Button>
                        </Modal.Footer>
                    </form>
                )}
        </Modal>
    );
};

export default OrderModal;