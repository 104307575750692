import React, { useEffect, useState } from 'react';
import { Card } from '../Ui';
import { Col, Container, Row } from 'react-bootstrap';
import SectionTitle from '../Ui/SectionTitle';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';
const WhyChoseMe = () => {

    const [whychooseusData, setWhychooseusData] = useState([]);

    useEffect(() => {
        axios.get('/whychooseus')
            .then(res => {
                setWhychooseusData(res.data.choose);
            })
    }, [])

    return (
        <div>
            <Breadcrumbs
                title='Why Choose Us'
                name='Accurate Record Keeping'
            ></Breadcrumbs>
            {/* Why Chose Us Area  */}
            <section className='why-chosse-us-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='This Is Why'
                        title='We Should Work Together'
                        des='We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards. '
                    />
                    <Row className='my-5'>
                        {
                            whychooseusData.map(WChoseUs =>
                                <Col key={WChoseUs.id} xl={4} lg={6}>
                                    <Card
                                        data={WChoseUs}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>
            <ScrollToTop />
        </div>
    );
};

export default WhyChoseMe;

