import React, { useEffect, useState } from 'react';
import { FilePath } from '../../Utils/Utils'
import Marquee from "react-fast-marquee";
import axios from '../../Utils/axios';
const Customar = () => {


    const [customarData, setCustomarData] = useState([])

    useEffect(() => {
        axios.get('/homepage')
            .then(res => {
                setCustomarData(res.data.customers)
            })
    }, [])    


    return (
        <div className='customar-images'>
            <Marquee pauseOnHover={true} gradient={true} gradientColor={[238, 242, 247]} direction={'right'} speed={40}>
                {
                    customarData.map(Customer =>
                        <img key={Customer.id} src={`${FilePath}${Customer.logo}`} alt="..." />
                    )
                }
            </Marquee >
        </div>
    );
};

export default Customar;