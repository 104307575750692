import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import SectionTitle from '../Ui/SectionTitle';
import Portfolio from '../Ui/Portfolio';
import './Portfolio.css';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';

const PortfolioPage = () => {

    const getCurrentSlug = useParams();
    const [categories, setCategories] = useState([]);
    const [projectsData, setProjectsData] = useState([]);


    useEffect(() => {

        axios.get('/all-categories')
            .then(res => {
                setCategories(res.data.data);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });

            getProjects();
      
    }, [getCurrentSlug.slug]);

    const getProjects = () => {
        let url = '/allprojects';
        if(getCurrentSlug.slug != undefined) {
            url = `/category/${getCurrentSlug.slug}`; 
        }

        axios.get(url)
        .then(res => {
            setProjectsData(res.data.data);
        })
        .catch(error => {
            console.error('Error fetching projects:', error);
        });

    }

    const filterItem = (cateItem) => {
        // setActiveFilter(cateItem);
        // if (cateItem === 'all') {
        //     setFilteredProjects(projectsData);
        // } else {
        //     const updatedItems = projectsData.filter((curElem) => {
        //         return curElem.category.name === cateItem;
        //     });
        //     setFilteredProjects(updatedItems);
        // }
    };

    console.log("categories", categories);

    return (
        <>
            <Breadcrumbs
                title=''
                name='Our Portfolio'
            ></Breadcrumbs>

            <section className="our-project">
                <Container>
                    <SectionTitle
                        subtitle='Latest Work'
                        title='Professional Experience'
                        des='Professional Design Agency to provide solutions.'
                    />
                    {/* Project Filter */}
                    <div className="project-item-filter">
                    <Link 
                        to="/portfolio"
                        className={`filter-btn ${getCurrentSlug.slug === undefined ? 'active' : ''}`}
                         >All</Link>
                        {categories?.map(category => (
                            <Link 
                            to={`/portfolio/${category.slug}`}
                            className={`filter-btn ${getCurrentSlug.slug === category.slug ? 'active' : ''}`}
                             >{category.name}</Link>
                        ))}
                    </div>
                    <Row className='my-5'>
                        {projectsData.map(project =>
                            <Col key={project.id} className="col-lg-4 col-md-4 col-12 col padding-13">
                                <Portfolio LatestWork={project} />
                            </Col>
                        )}
                    </Row>
                </Container>
                <ScrollToTop />
            </section>
        </>
    );
};

export default PortfolioPage;
