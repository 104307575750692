import React from 'react';
import './Breadcrumbs.css'
const Breadcrumbs = (props) => {
    return (
        <div className='breadcrumbs-section-wrap'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            {props.title}
                        </p>
                        <h3>{props.name}</h3>
                    </div>
                    <div className="col-md-6">
                        <img src="" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breadcrumbs;