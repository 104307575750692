import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SectionTitle from '../Ui/SectionTitle';
import { Customar, TestimonialCardPage } from '../Ui';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import axios from '../../Utils/axios';
import ScrollToTop from '../ScrollToTop';

const Testimonials = () => {

    const [testimonialsData, setTestimonialsData] = useState([])

    useEffect(() => {
        axios.get('/testimonials')
            .then(res => {
                setTestimonialsData(res.data.testimonials)
            })
    }, [])

    return (
        <>
            <Breadcrumbs
                name='Testimonials'
            ></Breadcrumbs>

            {/* Testimonials  */}
            <section className='testimonials-wrap'>
                <Container>
                    <SectionTitle
                        subtitle='Testimonials'
                        title='Whats Our Clients Say?'
                    />
                    <Row className='my-5'>
                        {
                            testimonialsData.map(Testimonial =>
                                <Col key={Testimonial.id} xl={4} lg={6}>
                                    <TestimonialCardPage
                                        Testimonial={Testimonial}
                                    />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </section>

            {/* Our Customers  */}
            <section className="our-customers-wrap">
                <Container>
                    <SectionTitle
                        subtitle=''
                        title='Our Customers'
                        des="We have built a large pool of knowledge that we apply to deliver solutions that meet client's needs, expectations and budget. By putting our clients at the heart of everything we do, we are proud that we are still supporting our very first business client."
                    />
                    <Row className='my-5'>
                        {/* {
                            Customers.slice(0, 18).map(Customer =>
                                <Col key={Customer.id} className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <Customar
                                        Customer={Customer}
                                    />
                                </Col>
                            )
                        } */}
                        <Customar></Customar>
                    </Row>
                </Container>
            </section>
            <ScrollToTop />
        </>
    );
};

export default Testimonials;