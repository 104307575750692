import React from 'react';
import './Cta.css'
import { Col, Container, Row } from 'react-bootstrap';
import ScrollToTop from '../ScrollToTop';
const Cta = () => {
    return (
        <section className='cta-area-wrap'>
            <Container>
                <Row>
                    <Col md={8} className='mx-auto text-center'>
                        <div className="cta-text">
                            <p>READY TO DO THIS</p>
                            <h1>Let's get to work</h1>
                            <a className='call-now' href="callto:+880 1859 59 72 73 ">Call Now</a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ScrollToTop />
        </section>
    );
};

export default Cta;