import React from 'react';
import { FilePath } from '../../Utils/Utils';

const TeamMember = (props) => {
    const { image, name, designation } = props.SingleTeam
    return (
        <div className='team-single-item'>
            <img src={`${FilePath}${image}`} alt="{name}" />
            <h3>{name}</h3>
            <p>{designation}</p>
        </div>
    );
};

export default TeamMember;