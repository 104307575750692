import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer, Cta } from '../../Components';
// import Footer from '../Footer/Footer';

const Layout = () => {
    return (
        <div>
            <Header></Header>
            <Outlet></Outlet>
            <Cta></Cta>
            <Footer></Footer>
        </div>
    );
};

export default Layout;